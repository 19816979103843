import React from 'react';
import Seo from '../components/seo';

import Layout from '../components/layout';
import CustomNavTitle from '../components/CustomNavTitle';

import { theme } from '../styles/theme';

const otherConsiderations = ({ location }) => {
  return (
    <Layout>
      <Seo title="Other Considerations" />

      <CustomNavTitle
        title="Other Considerations"
        destination="/"
        location={location}
      />

      <article css={theme.components.row} className="theme">
        <section className="max-w-content">
          <p>
            When providing the written instruction (prescribing) for blood
            component transfusion, the following considerations are also
            required:
          </p>

          <h3 className="pt-gap">Consent</h3>
          <p>
            There is a legal and ethical duty to involve patients in the
            decision process
          </p>

          <ul>
            <li>
              Patients have the right to be involved in discussions and make
              informed decisions about their care
            </li>
            <li>
              Informed and valid consent is completed for all patients who will
              likely, or definitely, receive a transfusion
            </li>
            <li>
              Patients who were not able to give informed and valid consent
              prior to the transfusion are informed of the transfusion prior to
              discharge
            </li>
          </ul>
          <p>Additional information can be obtained from the links below:</p>

          <p>
            Guidelines from SaBTO on patient consent for blood transfusion:
            <br />
            <a
              className="text-theme"
              href="https://www.gov.uk/government/publications/blood-transfusion-patient-consent">
              https://www.gov.uk/government/publications/blood-transfusion-patient-consent
            </a>
          </p>
          <p>
            Supreme Court Ruling: Montgomery Vs Lanarkshire Health Board (March
            2015)
            <br />
            <a
              className="text-theme"
              href="https://www.supremecourt.uk/cases/uksc-2013-0136.html">
              https://www.supremecourt.uk/cases/uksc-2013-0136.html
            </a>
          </p>
          <p>
            NICE Guideline and Quality Standards [NG24]
            <br />
            <a
              className="text-theme"
              href="https://www.nice.org.uk/guidance/ng24/chapter/Recommendations">
              https://www.nice.org.uk/guidance/ng24/chapter/Recommendations
            </a>
          </p>
          <p>
            GMC Decision making and consent:
            <br />
            <a
              className="text-theme"
              href="https://www.gmc-uk.org/ethical-guidance/ethical-guidance-for-doctors/decision-making-and-consent">
              https://www.gmc-uk.org/ethical-guidance/ethical-guidance-for-doctors/decision-making-and-consent
            </a>
          </p>
          <p>
            Patient Information Leaflets: NHSBT Hospitals and Science:
            <br />
            <a
              className="text-theme"
              href="https://hospital.blood.co.uk/patient-services/patient-blood-management/patient-information-leaflets/">
              https://hospital.blood.co.uk/patient-services/patient-blood-management/patient-information-leaflets/
            </a>
          </p>
          <p>
            Blood Assist - Patient Consent:
            <br />
            <a
              className="text-theme"
              href="https://www.bloodassist.co.uk/patient-consent">
              https://www.bloodassist.co.uk/patient-consent
            </a>
          </p>
          <p>
            Annual reviews NHSBT and PHE epidemiology unit's data and research
            activity infographics
            <br />
            <a
              className="text-theme"
              href="https://hospital.blood.co.uk/epidemiology-reports/">
              https://hospital.blood.co.uk/epidemiology-reports/
            </a>
          </p>

          <p>
            Review of the NBTC Indication Codes for Transfusion is not
            anticipated before 2023. These are current guidelines and may change
            depending on new evidence.
          </p>

          <h3 className="pt-gap">TACO Risk Assessment:</h3>

          <p>
            Transfusion associated pulmonary complications (mainly TACO) is a
            significant cause of death and major morbidity. Additional
            information can be obtained from the links below:
          </p>

          <p>
            SHOT TACO Checklist:
            <br />
            <a
              className="text-theme"
              href="https://www.shotuk.org/wp-content/uploads/myimages/TACO-checklist.pdf">
              https://www.shotuk.org/wp-content/uploads/myimages/TACO-checklist.pdf
            </a>
          </p>

          <p>
            Blood Assist – TACO Risk Assessment:
            <br />
            <a
              className="text-theme"
              href="https://www.bloodassist.co.uk/taco/">
              https://www.bloodassist.co.uk/taco/
            </a>
          </p>

          <h3 className="pt-gap">Specific Requirements</h3>

          <p>
            Blood Assist – Specific Requirements:
            <br />
            <a
              className="text-theme"
              href="https://www.bloodassist.co.uk/requirements/">
              https://www.bloodassist.co.uk/requirements/
            </a>
          </p>

          <p>
            BSH Guidelines on the use of irradiated blood components:
            <br />
            <a
              className="text-theme"
              href="https://b-s-h.org.uk/guidelines/guidelines/guidelines-on-the-use-of-irradiated-blood-components/">
              https://b-s-h.org.uk/guidelines/guidelines/guidelines-on-the-use-of-irradiated-blood-components/
            </a>
          </p>

          <h3 className="pt-gap">Alternatives to blood transfusion</h3>

          <p>
            PBM improves patient outcomes by improving the patient’s medical and
            surgical management in ways that boost and conserve the patient’s
            own blood. With better management, patients may require fewer
            transfusions, reducing the risk of transfusion-associated
            complications. Additional information can be obtained from the links
            below:
          </p>

          <p>
            Patient Blood Management:
            <br />
            <a
              className="text-theme"
              href="https://hospital.blood.co.uk/patient-services/patient-blood-management/">
              https://hospital.blood.co.uk/patient-services/patient-blood-management/
            </a>
          </p>

          <p>
            NICE: Alternatives to blood transfusion for patients having surgery:
            <br />
            <a
              className="text-theme"
              href="https://www.nice.org.uk/guidance/ng24/chapter/Recommendations#alternatives-to-blood-transfusion-for-patients-having-surgery-2">
              https://www.nice.org.uk/guidance/ng24/chapter/Recommendations#alternatives-to-blood-transfusion-for-patients-having-surgery-2
            </a>
          </p>

          <h3 className="pt-gap">General Information</h3>

          <p>
            Some additional useful resources can be obtained from the links
            below:
          </p>

          <p>
            Blood Assist:
            <br />
            <a className="text-theme" href="https://www.bloodassist.co.uk/">
              https://www.bloodassist.co.uk/
            </a>
          </p>

          <p>
            SHOT resources:
            <br />
            <a
              className="text-theme"
              href="https://www.shotuk.org/resources/current-resources/">
              https://www.shotuk.org/resources/current-resources/
            </a>
          </p>

          <p>
            BSH Transfusion Guidelines: <br />
            <a className="text-theme" href="https://b-s-h.org.uk/guidelines/">
              https://b-s-h.org.uk/guidelines/
            </a>
          </p>

          <p>
            PBM Pre-Transfusion Sampling Process video:
            <br />
            <a
              className="text-theme"
              href="https://www.youtube.com/watch?v=7tADOtE5RBQ&t=23s">
              https://www.youtube.com/watch?v=7tADOtE5RBQ&amp;t=23s
            </a>
          </p>
        </section>
      </article>
    </Layout>
  );
};

export default otherConsiderations;
